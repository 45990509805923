import React, { useState, useEffect, useContext} from 'react';
import InputMask from "react-input-mask";
import { Link, navigate } from 'gatsby';
// Structure imports
import Layout from '@components/structure/Layout';
// Utility imports
import Seo from '@components/utility/Seo';
import CtaButton from '@mui-components/ctaButton';
import { checkValiation } from '@components/utility/Validation';
import Preloader from '@components/structure/Preloader';
//MUI
import {
	Box,
	TextField,
	Grid,
	FormControl,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
} from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import { AppContext } from '@context'
import { Api } from '@components/utility/Api';

const RegisterIh = () => {
	const [getFields, setFields] = useState(() => []);
	const [webinars, setWebinars] = useState(() => []);
	const [loading, setLoading] = useState(() => false);
	const [dobError, setDobError] = useState('You must be at least 18 years old to sign up.');
	let form = '';

	const {selectedWebinars, setSelectedWebinars} = useContext(AppContext);

	const handleSubmit = (e) => {
		e.preventDefault();
		form = e.target;
		const inputs = form.querySelectorAll('input');
		const headingErrorEl = form.querySelector('.heading-error') || null;
		let fieldValid = true;
		let formValid = true;
		let UUIDs = [];

		inputs.forEach(function (element) {
			const elementID = element.name;
			fieldValid = checkValiation(form, elementID, element.type, getFields);

			if (!fieldValid && headingErrorEl) {
				formValid = false;
			}
		});

		if(!formValid) {
			headingErrorEl.classList.add('show-error');
			headingErrorEl.focus();

			let userBirthDate = getFields['dob'];

			const regexVar = new RegExp("([0-9]{2})\/([0-9]{2})\/([0-9]{4})");
			const regexVarTest = regexVar.test(userBirthDate);
			const newDate = userBirthDate && regexVarTest ? new Date(userBirthDate.replace(regexVar, "$3-$1-$2")) : 'Invalid';


			if(isNaN(newDate) || userBirthDate.replace(regexVar, "$3") < 1) {
				setDobError('Please enter a valid date of birth (MM/DD/YYYY).')
			}
			else {
				setDobError('You must be at least 18 years old to sign up.')
			}
		}
		else {
			headingErrorEl.classList.remove('show-error');
			setLoading(true);

			webinars.map((item, index) => {
				UUIDs.push(item[1].UUID)
			});
			let userBirthDate = getFields['dob'];
			if(userBirthDate.length == 8) {
				let tempYear = userBirthDate.split("/")[2];
				if(tempYear.length == 2 && tempYear <= 25) {
					tempYear = 20+tempYear;
					userBirthDate = userBirthDate.split("/")[0]+"/"+userBirthDate.split("/")[1]+"/"+tempYear;
				}
				if(tempYear.length == 2 && tempYear >= 26) {
					tempYear = 19+tempYear;
					userBirthDate = userBirthDate.split("/")[0]+"/"+userBirthDate.split("/")[1]+"/"+tempYear;
				}
			}
			// const registerData = {
			// 	"eventid": UUIDs.length > 1 ? UUIDs[0]+';'+UUIDs[1] : UUIDs[0],
			// 	"firstname": getFields['first-name'],
			// 	"lastname": getFields['last-name'],
			// 	"email": getFields['email-address'],
			// 	"zipcode":  getFields['zip-code'],
			// 	"dateofbirth":  userBirthDate,
			// 	"diagnosedwith": getFields['been-diagnosed-group'],
			// 	"treatment": getFields['been-diagnosed-group'] === 'idiopathhyper' ? getFields['on-treatment-group'] : "",
			// 	"treatmentih": (getFields['been-diagnosed-group'] === 'idiopathhyper' && getFields['on-treatment-group'] === 'yes') ? getFields['ih-treatment-take-group'] : "",
			// 	'bestdescribesih': getFields['been-diagnosed-group'] === 'idiopathhyper' && getFields['on-treatment-group'] === 'no' ? getFields['describes-you-group'] : "",
			// 	"multipleauthorization": "true"
			// }

			// const success = response => {
			// 	if(response.success) {
			// 		navigate("/idiopathic-hypersomnia/confirmation");
			// 	}
			// 	else {
			// 		console.log(response);
			// 		setLoading(false);
			// 	}
			// }
			// const fail = error => {
			// 	setLoading(false);
			// 	console.log('Internal Error ', error);
			// }
			// Api.post_register(success, fail, registerData, 3);

			sendDataJazzCX(userBirthDate,UUIDs)
		}
	};

	const sendDataJazzCX = (userBirthDate,UUIDs) => {

		let ihTreatmentType = '';
		let patientType ='';
		switch (getFields['ih-treatment-take-group']) {
			case 'oxybate':
				ihTreatmentType = 'Oxybate';
				break;
			case 'stimulant':
				ihTreatmentType = 'Stimulant';
				break;
			case 'wpa':
				ihTreatmentType = 'Wake-promoting agent (WPA)';
				break;
			case 'othernotsure':
				ihTreatmentType = 'I’m not sure what it’s called';
				break;
			default:
				ihTreatmentType = ''
		}

		switch (getFields['describes-you-group']) {
			case 'takenstopped':
				patientType = 'I’ve taken medication for IH in the past but stopped';
				break;
			case 'nevertaken':
				patientType = 'I’ve never taken medication for my IH';
				break;
			default:
				patientType = ''
		}

		const registerDataJazzCX = {}
		const ihTreatmentStatus =  getFields['been-diagnosed-group'] === 'idiopathhyper' ? getFields['on-treatment-group'] === 'yes' ? 'Yes' : 'No' : null;
		const ihTreatType = getFields['been-diagnosed-group'] === 'idiopathhyper' && getFields['on-treatment-group'] === 'yes' ? ihTreatmentType : null;

		registerDataJazzCX.firstName = getFields['first-name'];
		registerDataJazzCX.lastName = getFields['last-name'];
		registerDataJazzCX.email = getFields['email-address'];
		registerDataJazzCX.zipCode = getFields['zip-code'];
		registerDataJazzCX.optInEmail = "Yes";
		registerDataJazzCX.dateOfBirth = userBirthDate;
		registerDataJazzCX.eventId = UUIDs.length > 1 ? UUIDs[0]+';'+UUIDs[1] : UUIDs[0];
		registerDataJazzCX.dataSource = "JWPS-UNBRANDED-IH";
		registerDataJazzCX.ihPatient = getFields['been-diagnosed-group'] === 'idiopathhyper' ? 'Yes' : "No, but I want to learn more about IH";
		registerDataJazzCX.phoneNumber = getFields['phone-number'];
		registerDataJazzCX.optInSms = getFields['text-reminder-group'] === 'yes' ? 'Yes' : 'No';
		
		
		if(getFields['on-treatment-group'] === 'no' && patientType != '') {
			registerDataJazzCX.patientType = patientType;
		}

		if(ihTreatmentStatus) {
			registerDataJazzCX.ihTreatmentStatus  = ihTreatmentStatus
		}

		if(ihTreatType && ihTreatmentType != '') {
			registerDataJazzCX.ihTreatmentType  = ihTreatType;
		}
		
		

		const success = response => {
			if(response.success) {
				navigate("/idiopathic-hypersomnia/confirmation");
			}
			else {
				console.log(response);
			}
			setLoading(false);
		}
		const fail = error => {
			setLoading(false);
			console.log('Internal Error ', error);
		}

		Api.post_register_CX(success, fail, registerDataJazzCX);
	}

	const handleChange = (e) => {
		let fields = getFields;

		fields[e.target.name] =
			e.target.type === 'checkbox' ? e.target.checked : e.target.value;

		setFields(fields);

		showHiddenQuestion(e);

		if (form) {
			checkValiation(form, e.target.name, e.target.type, getFields);
		}
	};

	const showHiddenQuestion = (element) => {
		const hiddenQuestion1 = document.querySelector(".hidden-question-1");
		const hiddenQuestion2 = document.querySelector(".hidden-question-2");
		const hiddenQuestion3 = document.querySelector(".hidden-question-3");

		if(element.target.name === 'been-diagnosed-group') {
			if(element.target.value === 'idiopathhyper') {
				hiddenQuestion1.classList.add("show");
			}
			else {
				hiddenQuestion1.classList.remove("show");
				hiddenQuestion2.classList.remove("show");
				hiddenQuestion3.classList.remove("show");

			}
		}
		if(element.target.name === 'on-treatment-group') {
			if(element.target.value === 'yes') {
				hiddenQuestion2.classList.add("show");
				hiddenQuestion3.classList.remove("show");
			}
			else {
				hiddenQuestion2.classList.remove("show");
				hiddenQuestion3.classList.add("show");
			}
		}
	}

	const removeClicked = (e, key) => {
		e.preventDefault();
		let newData = [...webinars];

		newData = newData.filter((item, index) => index !== key)
		setWebinars(newData);
		setSelectedWebinars(Object.fromEntries(newData));
		redirectCheck(newData);
	}

	const redirectCheck = (data) => {
		if (!data.length) {
			navigate("/idiopathic-hypersomnia");
		}
	}

	useEffect(() => {
		const isEDMode = window.location.href.indexOf('ed=1') > -1;
		if(isEDMode) {
			const underAgeDOB = "01/01/2006";
			let isDobErrorShot = false;

			//screenshot
			const success = response => {
				if(window.location.search.substring(1).split('&').indexOf('error=dob') > -1) {
					isDobErrorShot = true;
				}
				let tempData = {};
				tempData[0] = {
						Date: response[0].Sessions[0].Date,
						ShortDescription: response[0].ShortDescription,
						Topic: response[0].Topic,
						UUID: response[0].Sessions[0].UUID,
				}
				setWebinars(Object.entries(tempData));

				if(isDobErrorShot) {
					setDobError('You must be at least 18 years old to sign up.')
				}
			}
			const fail = error => {
				console.log('Internal Error ', error);
			}
			Api.get_webinar(success, fail, "unbranded", "unbrandedih");

			if(isDobErrorShot) {
				let fields = getFields;
				fields['dob'] = underAgeDOB;
				setFields(fields);
			}

		}
		else {
			redirectCheck(Object.entries(selectedWebinars));
			setWebinars(Object.entries(selectedWebinars));
		}
	}, []);

	return (
		<Layout
			pageType='ih-unbranded'
			className='idiopathicHypersomniaUnbranded no-tabs'
			pixelCode='Start Registration'>

			<Seo title='Idiopathic Hypersomnia Webinar Registration | Jazz Webinars' description="If you're living with or caring for someone with idiopathic hypersomnia (IH), register today to gain access to the upcoming narcolepsy live webinars and educational programs." />

			<div>
				<StaticImage
					className='test-desktop'
					src='../../../static/images/ih-unbranded-hero.png'
					alt='Idiopathic hypersomnia Hero Banner Desktop on register page'
				/>
				<StaticImage
					className='test-mobile '
					src='../../../static/images/ih-unbranded-hero-mobile.png'
					alt='Idiopathic hypersomnia Hero Banner Mobile on register page'
				/>

				<section className='container firstSection'>
					<Grid item xs={12}>
						<span className='back-link'>
							&#8249; <Link to='/idiopathic-hypersomnia'>
										Back to Webinar Library
									</Link>
						</span>
						<div className='lv-copy'>
							<h1>
								<span>Stay up-to-date on</span>
								The Symptoms <br className='desktopOnly' />
								and Impact of <br className='desktopOnly' />
								Idiopathic <br className='desktopOnly' />
								Hypersomnia (IH)
							</h1>
						</div>
					</Grid>
				</section>
			</div>

			<section className='container'>
				<div>
					<h2 className='header centerText'>Just one last step!</h2>
					<div className='gradient-divider center'>
						<hr />
					</div>
					<p className='copy centerText'>
						To register for your live webinars, please complete the form
						below.<span className='desktopOnlyContent'> You can view, edit, or remove the webinars you’ve selected
						in the right-hand column.</span>
					</p>
				</div>
				<div className='selectWebinarSection formRight spaceTop'>
					<p className='label upperCase'>Your Selected Webinars</p>
					{webinars ? webinars.map((item, key) => (
						<div className='selectedWebinarItem' key={key}>
							<a href="#" onClick={(e) => {
								removeClicked(e, key);
							}}>Remove</a>
							<Link to='/idiopathic-hypersomnia'>Edit</Link>
							<p className='header2'>{item[1].Topic}</p>
							<p className='body2'>{item[1].ShortDescription || 'No description provided'}</p>
							<p className='timeDate'>TIME &amp; DATE</p>
							<p className='date'>{item[1].Date}</p>
						</div>
					)) : null}
				</div>
				<form
					name='registration'
					method='post'
					onSubmit={handleSubmit}>
					<p className='error-label heading-error' tabIndex='-1'>
						<strong>
							Please be sure to complete or correct the required field(s).
						</strong>
					</p>
					<p className='fieldsRequired'>
						All fields are required unless otherwise specified.
					</p>
					<div className=''>
						<Grid container justifyContent={'space-between'}>
							<Grid item xs={12} className='formLeft spaceTop'>
								<Box
									className='formLeftWidth'
									sx={{
										'& > :not(style)': { m: 1, width: '100%' },
									}}
									noValidate
									autoComplete='off'>
									<label htmlFor='first-name'>First Name</label>
									<p className='error-label first-name'>
										Please enter your First Name.
									</p>
									<TextField
										id='first-name'
										name='first-name'
										variant='outlined'
										color='primary'
										inputProps={{ maxLength: 50 }}
										onChange={handleChange}
									/>
									<label htmlFor='last-name'>Last Name</label>
									<p className='error-label last-name'>
										Please enter your Last Name.
									</p>
									<TextField
										id='last-name'
										name='last-name'
										variant='outlined'
										color='primary'
										inputProps={{ maxLength: 50 }}
										onChange={handleChange}
									/>
									<label htmlFor='email-address'>Email Address</label>
									<p className='error-label email-address'>
										Please enter a valid Email Address.
									</p>
									<TextField
										id='email-address'
										name='email-address'
										variant='outlined'
										color='primary'
										inputProps={{ maxLength: 50 }}
										onChange={handleChange}
									/>
									<label htmlFor='phone-number'>
										Mobile Phone Number
									</label>
									<p className='error-label phone-number'>
										Please enter a valid Mobile Phone Number.
									</p>
									<TextField
										id='phone-number'
										name='phone-number'
										variant='outlined'
										color='primary'
										inputProps={{ maxLength: 10 }}
										onChange={handleChange}
									/>
									<label htmlFor='zip-code'>ZIP Code</label>
									<p className='error-label zip-code'>
										Please enter a valid ZIP Code.
									</p>
									<InputMask
										mask="99999"
										onChange={handleChange}
										maskChar=""
									>
										{() => <TextField
											id='zip-code'
											name='zip-code'
											variant='outlined'
											color='primary'
										/>}
									</InputMask>
									<label htmlFor='dob'>Date of Birth (MM/DD/YYYY)</label>
									<p className='error-label dob'>
										{dobError}
									</p>
									<InputMask
										mask="99/99/9999"
										onChange={handleChange}
										maskChar=""
									>
										{() => <TextField
											id='dob'
											name='dob'
											variant='outlined'
											color='primary'
										/>}
									</InputMask>
								</Box>
								<div className='formLeftWidth'>
										<p
											id='text-reminder'
											className='textReminderText'>
											<strong>
												Would you like a text reminder?
											</strong>
										</p>
										<p>
											Messaging and data rates may apply. Reply STOP
											to 98637 to cancel and/or HELP for help. Up to
											10 msg/mo.{' '}
											<a href='/terms-conditions' target={"_blank"}>SMS Terms &amp; Conditions</a>
										</p>
										<p className='error-label radio-padding-fix text-reminder-group'>
											Please select an option.
										</p>
										<FormControl>
											<RadioGroup
												row
												onChange={handleChange}
												aria-labelledby='text-reminder'
												name='text-reminder-group'>
												<FormControlLabel
													value='yes'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='Yes'
												/>
												<FormControlLabel
													value='no'
													control={
														<Radio
															color='primary'
															sx={{
																'& .MuiSvgIcon-root': {
																	fontSize: 32,
																},
															}}
														/>
													}
													label='No'
												/>
											</RadioGroup>
										</FormControl>
									</div>
								<div className='formLeftWidth'>
									<p className='bold' id='been-diagnosed'>
										Have you been diagnosed with Idiopathic
										Hypersomnia?
									</p>
									<p className='error-label radio-padding-fix been-diagnosed-group'>
										Please select an option.
									</p>
									<FormControl>
										<RadioGroup
											row
											onChange={handleChange}
											aria-labelledby='been-diagnosed'
											name='been-diagnosed-group'>
											<FormControlLabel
												value='idiopathhyper'
												control={<Radio color='primary' sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}
												/>}
												label='Yes'
											/>
											<FormControlLabel
												value='nobutinterested'
												control={<Radio color='primary' sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}
												/>}
												label='No, but I want to learn more about IH'
											/>
										</RadioGroup>
									</FormControl>
								</div>

								<div className='formLeftWidth IhTreatment hidden-question-1'>
									<p className='bold' id='on-treatment'>
										Are you currently on treatment for your IH?
									</p>
									<p className='error-label radio-padding-fix on-treatment-group'>
										Please select an option.
									</p>
									<FormControl>
										<RadioGroup
											row
											onChange={handleChange}
											aria-labelledby='on-treatment'
											name='on-treatment-group'>
											<FormControlLabel
												value='yes'
												control={<Radio color='primary' sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}/>}
												label='Yes'
											/>
											<FormControlLabel
												value='no'
												control={<Radio color='primary' sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}/>}
												label='No'
											/>
										</RadioGroup>
									</FormControl>
								</div>

								<div className='IhTypeTreatment hidden-question-2'>
									<p className='bold' id="ih-treatment-take">
										Select the type of treatment you take for IH:{' '}
										<span className='optional'>(optional)</span>
									</p>

									<RadioGroup
											onChange={handleChange}
											name='ih-treatment-take-group'
											aria-labelledby='ih-treatment-take'>
											<FormControlLabel
												value='oxybate'
												control={
													<Radio
														color='primary'
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 32,
															},
														}}
													/>
												}
												label='Oxybate'
											/>
											<FormControlLabel
												value='stimulant'
												control={
													<Radio
														color='primary'
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 32,
															},
														}}
													/>
												}
												label='Stimulant'
											/>
											<FormControlLabel
												value='wpa'
												control={
													<Radio
														color='primary'
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 32,
															},
														}}
													/>
												}
												label='Wake-promoting agent (WPA)'
											/>
											<FormControlLabel
												value='othernotsure'
												control={
													<Radio
														color='primary'
														sx={{
															'& .MuiSvgIcon-root': {
																fontSize: 32,
															},
														}}
													/>
												}
												label="I'm not sure what it's called"
											/>
										</RadioGroup>
								</div>

								<div className='IhTypeTreatment hidden-question-3'>
									<p className='bold' id="describes-you">
										Which best describes you?
										<span className='optional'> (optional)</span>
									</p>
									<FormControl>
										<RadioGroup
											onChange={handleChange}
											aria-labelledby='describes-you'
											name='describes-you-group'>
											<FormControlLabel
												value="takenstopped"
												control={<Radio color='primary' sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}/>}
												label="I've taken medication for IH in the past but stopped"
											/>
											<FormControlLabel
												value="nevertaken"
												control={<Radio color='primary' sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}/>}
												label="I've never taken medication for my IH"
											/>
										</RadioGroup>
									</FormControl>
								</div>
								<div className='consentCheckboxContainer formLeftWidth'>
									<FormControlLabel
										onChange={handleChange}
										control={
											<Checkbox
												name='consent'
												color='primary'
												className='consentCheckbox'
												onChange={handleChange}
												sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 32,
													},
												}}
											/>
										}
										label={<span>By checking this box, you confirm that you are a resident of the U.S. and would like to receive information from Jazz Pharmaceuticals about educational programs, products, and services. You&nbsp;can opt out at any time.</span>}
									/>
									<br />
									<p className='error-label consent'>
										Please check the box above to continue.
									</p>
								</div>
								<div className='privacyStatmentLink'>
										<a href='https://www.jazzpharma.com/privacy-statement/' target="_blank">Privacy Statement</a>
								</div>

								<div className='registerButton'>
									{!loading ?
										<CtaButton
											title='REGISTER'
											class='cta main-cta registerButtonItem'
											type='submit'
											disabled={loading}
										/>
									: <Preloader/> }
								</div>
							</Grid>
						</Grid>
					</div>
				</form>
			</section>
		</Layout>
	);
};

export default RegisterIh;
